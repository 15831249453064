<template>
  <div class="auth-form__wrap">
    <form class="auth-form">
      <div class="auth-form__title">Смена пароля</div>

      <div class="auth-form__elements">
        <ElementForm
            :data="form?.password"
            :activeValidate="activeValidate"
            :class="{'error': errorPasswordMismatch}"
            @change="handleChange($event, form?.password?.name)"
            @update-validate="setValidate($event, form?.password?.name)"
        />
        <ElementForm
            :data="form?.confirmationPassword"
            :activeValidate="activeValidate"
            :error="errorPasswordMismatch"
            @change="handleChange($event, form?.confirmationPassword?.name)"
            @update-validate="setValidate($event, form?.confirmationPassword?.name)"
        />
        <a href="/" class="auth-form__toogle-form">
          Авторизоваться
        </a>
      </div>

      <div class="auth-form__btns">
        <ButtonUI
          title="Изменить пароль"
          type="submit"
          @click="submitForm"
        />
      </div>

      <div v-if="errors?.length" class="auth-form__errors">
        <div v-for="item in errors" :key="item">{{ item }}</div>
      </div>
    </form>
  </div>

  <Loader v-if="showLoader" />
</template>

<script>
import { api } from '../../../api';
import Loader from '../Loader';
import ElementForm from '../ui/Form/ElementForm'
import ButtonUI from '../ui/ButtonUI'
import { scrollToFirstElementWithError } from '../ui/Form/utils'

export default {
  name: 'FormChangePassword',
  components: {
    Loader,
    ElementForm,
    ButtonUI
  },
  data () {
    return {
      showLoader: false,
      activeValidate: false,
      model: {},
      validate: {},
      form: {
        password: {
          type: 'input',
          name: 'password',
          attributes: {
            label: 'Пароль',
            placeholder: 'Введите пароль',
            type: 'password'
          },
          validators: {
            required: true,
            minLength: {
              quantity: 6
            }
          }
        },
        confirmationPassword: {
          type: 'input',
          name: 'confirmationPassword',
          attributes: {
            label: 'Пароль',
            placeholder: 'Подтвердите пароль',
            type: 'password'
          },
          validators: {
            required: true,
            minLength: {
              quantity: 6
            }
          }
        }
      },
      errors: [],
      errorPasswordMismatch: ''
    }
  },
  methods: {
    handleChange (value, name) {
      this.model[name] = value
      this.errors = []
      this.errorPasswordMismatch = ''
    },
    setValidate (value, name) {
      if (value) {
        this.validate[name] = value
      } else if (this.validate[name]) {
        delete this.validate[name]
      }
    },
    submitForm ($event) {
      if ($event) {
        this.activeValidate = true
        const isValid = Object.keys(this.validate)?.length === 0 && this.errors?.length === 0

        if (isValid && this.model?.password !== this.model?.confirmationPassword) {
          this.errorPasswordMismatch = 'Пароли не совпадают!'
        }

        if (isValid && !this.errorPasswordMismatch) {
          // Тогда отправляем форму
          this.showLoader = true
          const urlParams = this.getQueryParamValue()

          api.sendChangePasswordForm({
            password: this.model?.password,
            confirmationPassword: this.model?.confirmationPassword,
            checkword: urlParams?.USER_CHECKWORD,
            login: urlParams?.USER_LOGIN
          })
              .then((result) => {
                if (result?.data?.data?.success) {
                  // При успешной смене пароля - переходим на страницу авторизации
                  window.location.href = '/'
                } else if (result?.data?.data?.errors?.length) {
                  // Если ошибка - заполняем её текстом с бэка
                  this.errors = result?.data?.data?.errors
                }

                this.showLoader = false
              })
              .catch(() => {
                this.showLoader = false
              })
        } else {
          scrollToFirstElementWithError(`[data-form-control="${Object.keys(this.validate)?.[0]}"]`)
        }
      }
    },
    getQueryParamValue () {
      const query = window.location.search.substring(1);
      const vars = query.split("&");
      const result = {}

      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        result[pair[0]] = pair[1];
      }

      return result;
    }
  }
};
</script>
